const MENU_BTN = document.querySelector('.menu-btn')
const MENU = document.querySelector('.header__navigation')

// Переключение Каталогов
const CATALOG_BTN = document.querySelectorAll('.catalog__button')
const CATALOG_ITEMS = document.querySelectorAll('.catalog-item')

// Модальные окна
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')
const MODAL_BG = document.querySelector('.modal-bg')

// Фикс работы FancyBox
const SLIDE = document.querySelectorAll('.slide-image')
const GALLERY_LIST = document.createElement('div')
const BLOCK_CATALOG = document.querySelector('.catalog')
const ABOUT = document.querySelectorAll('.about-img')
const ABOUT_LIST = document.createElement('div')
const BLOCK_ABOUT = document.querySelector('.about')

// Смена изображения первого экрана по запросу в ссылке
const MAIN_BG = document.querySelector('.bg-img')

// Функция появления кнопки прокрутки вверх
const SCROLL_BTN = document.querySelector('.top-scroll')

//Раскрытие меню
MENU_BTN.addEventListener('click', () => {
    MENU_BTN.classList.toggle('active')
    MENU.classList.toggle('active')
})

// Переключение Каталогов
function setActiveTab(activeTab) {
    CATALOG_BTN.forEach((item) => {
        if (item.getAttribute('data-item') !== activeTab) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }
    })
}
setActiveTab('1')

function setActiveCatalog(activeCatalog , activeTab) {
    CATALOG_ITEMS.forEach((item) => {
        if (item.getAttribute('data-item') !== activeCatalog) {
            item.classList.add('active')
        } else {
            item.classList.remove('active')
        }
    })
}
setActiveCatalog('1')


// Смена изображения и заголовка в первом блоке в зависимости от текста в адресной строке
const url = location.href
const headlineText = document.querySelector('.main__title')
const headline = decodeURI(location.hash).replace('#', '')
if (headline) {
    headlineText.innerText = headline
}

CATALOG_BTN.forEach((item) => {

    let title = item.getAttribute('data-title')

    if (title === headline) {
        MAIN_BG.src = "img/tube-2.png"
        setActiveCatalog('2')
        setActiveTab('2')
    } else {
        MAIN_BG.src = "img/tube-1.png"
        setActiveCatalog('1')
        setActiveTab('1')
    }

    item.addEventListener('click', () => {
        CATALOG_BTN.forEach((item) => {
            item.classList.remove('active')
        })
        item.classList.add('active')
        setActiveCatalog(item.getAttribute('data-item'))
    })
})

// Инициализация Слайдеров
const certificate = new Swiper('.certificate-slider', {
    loop: true,
    speed: 400,
    clickable: true,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 20,
    navigation: {
        nextEl: '.certificate-button-next',
        prevEl: '.certificate-button-prev',
    },

    pagination: {
        el: '.certificate-pagination',
        type: 'bullets',
    },
});

const gallery = new Swiper('.gallery-slider', {
    // loop: true,
    speed: 400,
    clickable: true,
    slidesPerView: 1,
    slideShow: 1,
    centeredSlides: true,
    spaceBetween: 20,
    navigation: {
        nextEl: '.gallery-button-next',
        prevEl: '.gallery-button-prev',
    },

    pagination: {
        el: '.gallery-pagination',
        type: 'bullets',
        clickable: true,
    },

});

const projects = new Swiper('.projects__slider', {
    loop: true,
    speed: 400,
    clickable: true,
    slidesPerView: "auto",
    centeredSlides: false,
    spaceBetween: 20,
    navigation: {
        nextEl: '.projects-button-next',
        prevEl: '.projects-button-prev',
    },
});


// Фикс работы FancyBox
BLOCK_CATALOG.appendChild(GALLERY_LIST)
GALLERY_LIST.classList.add('certificate')
GALLERY_LIST.setAttribute('hidden', '')

SLIDE.forEach((item) => {
    let link = document.createElement('a')
    link.setAttribute('href', `${item.getAttribute('src')}`)
    link.setAttribute('data-fancybox', 'gallery')
    link.innerHTML = `<img src="${item.getAttribute('src')}" alt="img">`

    GALLERY_LIST.appendChild(link)
    item.addEventListener('click', (event) => {
        if (event.target.classList.contains('slide-image')) {
            link.click()
        }
    })
})

BLOCK_ABOUT.appendChild(ABOUT_LIST)
ABOUT_LIST.classList.add('about')
ABOUT_LIST.setAttribute('hidden', '')

ABOUT.forEach((item) => {
    let link = document.createElement('a')
    link.setAttribute('href', `${item.getAttribute('src')}`)
    link.setAttribute('data-fancybox', 'about')
    link.innerHTML = `<img src="${item.getAttribute('src')}" alt="img">`

    ABOUT_LIST.appendChild(link)
    item.addEventListener('click', (event) => {
        if (event.target.classList.contains('about-img')) {
            link.click()
        }
    })
})

// Открытие/закрытие модальных окон
function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    // htmlScroll()
}

closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})

openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                // hideScroll()
            }
        })
    })
})

//Прикрепить файл
$(document).ready(function () {
    $("#modalUpload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-modal").html(filename);
    });
});

$(document).ready(function () {
    $("#uploadDiscount").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-discount").html(filename);
    });
});

$(document).ready(function () {
    $("#uploadForm").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-form").html(filename);
    });
});

$(document).ready(function () {
    $("#formUpload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#fileName").html(filename);
    });
});

// Функция появления кнопки прокрутки вверх
const FOOTER = document.querySelector('.footer')
let pageHeight = document.documentElement.scrollHeight
let footerHeight = FOOTER.clientHeight
let windowInnerHeight = window.innerHeight
let width = window.innerWidth

window.addEventListener('resize', () => {
    pageHeight = document.documentElement.scrollHeight
    footerHeight = FOOTER.offsetHeight
    windowInnerHeight = window.innerHeight
    width = window.innerWidth
})

let prev = 0

window.addEventListener('scroll', (event) => {

    let scrollPosition = pageHeight - footerHeight - 200
    let position = scrollY + windowInnerHeight

    if (scrollPosition <= position) {
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '30px'
        }
        if (width < 1366) {
            SCROLL_BTN.style.bottom = '80px'
        }

    } else {
        SCROLL_BTN.style.bottom = '30px'
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '75px'
        }
    }

    if (prev > scrollY && scrollY > 300) {
        SCROLL_BTN.classList.add('active')

    } else {
        SCROLL_BTN.classList.remove('active')
    }

    prev = scrollY
})

//Тост об успешной отправке формы
const TOAST = document.querySelector('.toast')

function closeToast(){
    setTimeout(() => {
        TOAST.classList.remove('active')
    }, 2000)
}

//Отправка статистики
const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')
function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}

//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    setDataClient()

});

function setDataClient() {
    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }
}

//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault();
        setDataClient()
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        }).done(function () {
            let fileName = 'Прикрепить файл';
            $('form').trigger('reset');
            $("#file-name-modal").html(fileName);
            $("#file-name-discount").html(fileName);
            $("#file-name-form").html(fileName);
            $("#fileName").html(fileName);
            TOAST.classList.add('active');
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            closeModal()
            sendStats()
            closeToast()
        });
    });
});

// Плитка текгов

const TAG_BTN = document.querySelector('.footer__ceo-btn')
const TAG_LIST = document.querySelector('.footer__ceo-list')
const TAG_TITLE = document.querySelector('.footer__ceo-title')

TAG_BTN.addEventListener('click', () => {
    TAG_LIST.classList.toggle('active')
    TAG_TITLE.classList.toggle('active')
})